<template>

    <div>

        <el-divider class="mx-0"></el-divider>

        <div class="row">

            <div class="col-10 col-sm-11">

                <div class="row">

                    <div class="col-md-3 col-4">

                        <p class="m-0"><b>Asignado de revisión</b></p>

                        <p>{{ case_assignment_default.userName }}</p>

                    </div>

                    <div class="col-md-2 col-4">

                        <p class="m-0"><b>Estatus</b></p>

                        <el-tag
                            v-if="case_assignment_default.active"
                            size="mini"
                            type="success"
                            class="mx-0"
                        >
                             Activo
                        </el-tag>

                        <el-tag v-else size="mini" type="info" class="mx-0">
                             Finalizado
                        </el-tag>

                    </div>

                    <div class="col-md-3 col-4">

                        <p class="m-0"><b>Actividad</b></p>
                         {{ case_assignment_default.activityName }}
                    </div>

                    <div class="col-md-4 col-12">

                        <p class="m-0"><b>Instrucciones de asignación</b></p>

                        <p>{{ case_assignment_default.description }}</p>

                    </div>

                    <div class="col-md-3 col-4">

                        <p class="m-0"><b>Asignada el </b></p>

                        <p>
                             {{ moment(case_assignment_default.created_at).format("DD-MM-YYYY")
                            }}
                        </p>

                    </div>

                    <div class="col-md-2 col-4">

                        <p class="m-0"><b>En un tiempo de</b></p>

                        <p>{{ case_assignment_default.daysSolve }} días</p>

                    </div>

                    <div class="col-md-3 col-4">

                        <p class="m-0"><b>Fecha de ultima actualización</b></p>

                        <p>
                             {{ moment(case_assignment_default.update_at).format("DD-MM-YYYY")
                            }}
                        </p>

                    </div>

                    <div class="col-md-4 col-4">

                        <p class="m-0"><b>Tiempo real resolución</b></p>
                         {{ this.dateDiff( moment(case_assignment_default.created_at).format("YYYY-MM-DD"),
                        moment(case_assignment_default.update_at).format("YYYY-MM-DD")
                        ) }} días
                    </div>

                </div>

            </div>

            <div class="col-2 col-sm-1">

                <div class="row">

                    <div
                        v-if="auth.isSuper || auth.isAssigner"
                        class="col pr-3 pl-0"
                    >

                        <el-tooltip
                            content="Editar"
                            placement="top"
                            v-if="
                                (auth.isSuper ||
                                    auth.isAssigner ||
                                    auth.id ==
                                        case_assignment_default.user_id) &&
                                    case_assignment_default.active
                            "
                        >

                            <el-button
                                class="btn-big-icon d-flex align-items-end ml-auto"
                                type="primary"
                                icon="big-icon el-icon-s-custom"
                                @click="toggleModal"
                            />

                        </el-tooltip>

                    </div>

                    <!-- Terminar asiganción -->

                    <div
                        v-if="case_assignment_default.active"
                        class="col pr-3 pl-0 mt-2"
                    >

                        <el-tooltip content="Finalizar tarea" placement="top">

                            <el-button
                                class="btn-big-icon d-flex align-items-end ml-auto"
                                type="danger"
                                plain
                                icon="big-icon el-icon-close"
                                @click="toggleModalClose"
                            />

                        </el-tooltip>

                    </div>

                </div>

            </div>

            <div class="col-sm-12">

                <el-progress
                    class="width-bar"
                    :percentage="case_assignment_default.percentage"
                    :color="customColors"
                    ref="progress"
                ></el-progress>

            </div>

            <div
                v-if="!case_assignment_default.active"
                class="col-md-6 col-6 mt-2"
            >

                <p class="m-0"><b>Comentarios finales</b></p>

                <div v-if="case_assignment_default.comment ? true : false">

                    <p>{{ case_assignment_default.comment }}</p>

                </div>

                <div v-else>

                    <p> No se agregan comentarios finales </p>

                </div>

            </div>

            <div
                v-if="!case_assignment_default.active"
                class="col-md-6 col-6 mt-2"
            >

                <p class="m-0"><b>Fecha de finalización tarea</b></p>

                <p>
                     {{ moment(case_assignment_default.update_at).format("DD-MM-YYYY")
                    }}
                </p>

            </div>

        </div>

        <!-- ---------------------------------------------Modales------------------------------------------ -->

        <!-- Editar caso -->

        <el-dialog class="updateDialog" :visible.sync="openModal">

            <h5 slot="title">
                 Actualizacion de la asignación del caso {{ case_assignment.complaint_id
                }}
            </h5>

            <el-form
                id="local_form"
                ref="ruleForm"
                :rules="rules"
                :model="case_assignment"
                @click.prevent="submitForm('ruleForm')"
            >

                <el-form-item label="Fecha de asignación:">
                     {{ moment(case_assignment.created_at).format("DD-MM-YYYY") }}

                </el-form-item>

                <el-form-item
                    label="Asignar a:"
                    class="w-100"
                    filterable
                    prop="user_id"
                    id="user_id"
                >

                    <!-- v-model="investigator" -->

                    <el-select
                        v-model="case_assignment.user_id"
                        class="w-100"
                        :disabled="!(auth.isSuper || auth.isAssigner)"
                        filterable
                    >

                        <el-option
                            v-for="user in users"
                            :key="user.id"
                            :value="user.id"
                            :label="user.name"
                        ></el-option>

                    </el-select>

                </el-form-item>

                <el-form-item
                    label="Días para realizar la acción:"
                    prop="daysSolve"
                    id="daysSolve"
                >

                    <!-- v-model="days" -->

                    <el-input-number
                        :min="1"
                        class="w-100"
                        v-model="case_assignment.daysSolve"
                    ></el-input-number>

                </el-form-item>

                <el-form-item
                    label="Actividad:"
                    prop="activity_types_id"
                    id="activity_types_id"
                >

                    <el-select
                        v-model="case_assignment.activity_types_id"
                        class="w-100"
                        clearable
                        filterable
                    >

                        <el-option
                            v-for="activity in activities"
                            :key="activity.id"
                            :value="activity.id"
                            :label="activity.name"
                        ></el-option>

                    </el-select>

                </el-form-item>

                <el-form-item
                    prop="description"
                    id="description"
                    label="Instrucciones de asignación:"
                    class="d-flex align-items-start"
                >

                    <el-input
                        type="textarea"
                        :rows="5"
                        class="w-100"
                        placeholder="Ingrese las instrucciones"
                        v-model="case_assignment.description"
                    >

                        <!-- v-model="textarea" -->

                    </el-input>

                </el-form-item>

                <el-form-item label=" ">

                    <el-checkbox v-model="case_assignment.close" class="w-100">
                         Finalizar asignación
                    </el-checkbox>

                </el-form-item>

            </el-form>

            <el-alert
                v-if="case_assignment.close"
                title="Finalizar asignación del caso"
                type="error"
                :closable="false"
            >

            </el-alert>

            <span slot="footer" class="dialog-footer">

                <el-button @click="toggleModal">Volver</el-button>

                <el-button
                    type="primary"
                    @click.prevent="submitForm('ruleForm')"
                    :disabled="
                        !truthty(case_assignment.user_id) ||
                            !truthty(case_assignment.daysSolve)
                    "
                    :loading="loading"
                >
                     Actualizar
                </el-button>

            </span>

        </el-dialog>

        <!-- Finalizar Tarea -->

        <el-dialog class="updateDialog" :visible.sync="openModalClose">

            <h5 slot="title">
                 Finalización de la asignación del caso {{ case_assignment.complaint_id
                }}
            </h5>

            <el-form
                id="local_form"
                ref="ruleForm"
                :rules="rules"
                :model="case_assignment"
                @click.prevent="submitForm('ruleForm')"
            >

                <el-form-item label="Fecha de asignación:">
                     {{ moment(case_assignment.created_at).format("DD-MM-YYYY") }}

                </el-form-item>

                <el-form-item label="Asignado a:">
                     {{ case_assignment.user.name }}
                </el-form-item>

                <el-form-item label="Días para realizar la acción:">
                     {{ case_assignment.daysSolve }}
                </el-form-item>

                <el-form-item label="Actividad:">
                     {{ case_assignment.activity_types.name }}
                </el-form-item>

                <el-form-item label="Instrucciones de asignación:">
                     {{ case_assignment.description }}
                </el-form-item>

                <el-form-item
                    prop="comment"
                    id="comment"
                    label="Comentarios finales:"
                    class="d-flex align-items-start"
                >

                    <el-input
                        type="textarea"
                        :rows="5"
                        class="w-100"
                        placeholder="Ingrese los comentarios finales"
                        v-model="case_assignment.comment"
                    >

                    </el-input>

                </el-form-item>

            </el-form>

            <span slot="footer" class="dialog-footer">

                <el-button @click="toggleModalClose">Volver</el-button>

                <el-button
                    type="primary"
                    @click.prevent="
                        (case_assignment.close = true), submitForm('ruleForm')
                    "
                    :loading="loading"
                >
                     Finalizar Tarea
                </el-button>

            </span>

        </el-dialog>

    </div>

</template>

<script>
const colors = { 33.3: "#67C23A", 66.6: "#E6A23C", 100: "#F56C6C" };
import moment from "moment";
export default {
    props: [
        "case_assignment_default",
        "auth",
        "users",
        "getComplaints",
        "activities"
    ],
    data() {
        return {
            loading: false,
            openModal: false,
            openModalClose: false,
            case_assignment: {
                ...this.case_assignment_default,
                close: this.case_assignment_default.active != 1,
                comment: ""
            },

            customColors: [
                { color: colors[33.3], percentage: 33.3 },
                { color: colors[66.6], percentage: 66.6 },
                { color: colors[100], percentage: 100 }
            ],
            rules: {
                description: [
                    {
                        required: true,
                        message: "Debe agregar la descripción",
                        trigger: ["change", "blur"]
                    }
                ],
                user_id: [
                    {
                        required: true,
                        message: "Debe escoger al usuario asignado",
                        trigger: ["change", "blur"]
                    }
                ],
                activity_types_id: [
                    {
                        required: true,
                        message: "Debe escoger la actividad a asignar",
                        trigger: ["change", "blur"]
                    }
                ],
                daysSolve: [
                    {
                        required: true,
                        message: "Debe agregar días asignados",
                        trigger: ["change", "blur"]
                    }
                ]
            }
        };
    },
    methods: {
        dateDiff(startDateString, endDateString) {
            const start = moment(startDateString);
            const end = moment(endDateString);
            const duration = moment.duration(end.diff(start));
            let days = duration.asDays();
            return Math.round(days);
        },
        toggleModalClose() {
            if (this.openModalClose) {
                this.case_assignment = {
                    ...this.case_assignment_default,
                    close: this.case_assignment_default.active != 1
                };
            }
            this.openModalClose = !this.openModalClose;
        },
        toggleModal() {
            if (this.openModal) {
                this.case_assignment = {
                    ...this.case_assignment_default,
                    close: this.case_assignment_default.active != 1
                };
            }
            this.openModal = !this.openModal;
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid, rules) => {
                if (valid) {
                    this.update();
                } else {
                    this.goToError(rules);
                }
            });
        },
        update() {
            const url = `${window.location.origin}/caseAssignment/${this.case_assignment.id}`;
            this.loading = true;
            axios
                .put(url, this.case_assignment)
                .then(response => {
                    this.loading = false;
                    if (response.status) {
                        this.getComplaints();
                        if (this.openModal) {
                            this.toggleModal();
                        } else {
                            this.toggleModalClose();
                        }
                    }
                })
                .catch(error => {
                    console.error(error.response);
                });
        }
    },
    watch: {},
    mounted() {}
};
</script>

<style>

</style>


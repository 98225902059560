<template>

    <div class="bg-light mt-3 px-md-4 px-3 py-2">

        <div>

            <div id="local_form" class="w-100">

                <div>

                    <h5>Ajustes de página</h5>

                </div>

                <div class="row">

                    <div class="col">

                        <label>Ajuste asociado</label>

                        <el-select
                            v-model="setting_id"
                            class="w-100"
                            @change="getSettingsPages($event)"
                        >

                            <el-option
                                v-for="setting in settings"
                                :key="setting.id"
                                :label="
                                    setting.business_name +
                                        ' País:' +
                                        setting.country.name +
                                        ' Lenguaje:' +
                                        setting.language.name
                                "
                                :value="setting.id"
                            >

                            </el-option>

                        </el-select>

                        <hr />

                    </div>

                </div>

                <el-form
                    class="row w-100 p-0 mx-0"
                    v-if="setting_id"
                    ref="ruleForm"
                    id="ruleForm"
                    :rules="rules"
                    :model="settingsPage"
                    v-loading="waiting"
                    @submit.prevent="submitForm('ruleForm')"
                    status-icon
                >

                    <div class="row mx-0 w-100">

                        <label
                            class="pt-2 mr-3"
                            style="font-size: 1rem"
                            id="description_1"
                        >
                             Linea ética
                        </label>

                    </div>

                    <div class="row mx-0 w-100 d-flex justify-content-between">

                        <el-form-item
                            prop="description_1"
                            class="col-md-7 px-0"
                        >

                            <label class="pt-2 mr-3">Descripción: </label>

                            <el-input
                                type="textarea"
                                :rows="5"
                                v-model="settingsPage.description_1"
                                placeholder="Ingrese la descripción"
                            ></el-input>

                        </el-form-item>

                        <el-form-item
                            prop="privacy"
                            id="privacy"
                            class="col-md-4 p-0"
                        >

                            <label class="pt-2 mr-3">
                                 Ingrese política de privacidad:
                            </label>

                            <el-upload
                                class="upload-demo"
                                action="#"
                                :on-change="handleChangePrivacy"
                                :on-remove="handleRemovePrivacy"
                                :on-preview="handlePreviewPrivacy"
                                :auto-upload="false"
                                :limit="1"
                                :on-exceed="handleExceedPrivacy"
                                :file-list="settingsPage.privacy"
                                accept="application/pdf"
                            >

                                <el-button size="small" type="primary">
                                     Click para subir archivo
                                </el-button>

                                <div slot="tip" class="el-upload__tip">
                                     Tamaño máximo de archivo 10MB en formato PDF

                                </div>

                            </el-upload>

                        </el-form-item>

                    </div>

                    <div class="row mx-0 w-100">

                        <label
                            class="pt-2 mr-3"
                            style="font-size: 1rem"
                            id="description_2"
                        >
                             Buenas practicas
                        </label>

                    </div>

                    <div class="row mx-0 w-100">

                        <el-form-item prop="description_2" class="col px-0">

                            <label class="pt-2 mr-3">Descripción: </label>

                            <el-input
                                type="textarea"
                                :rows="5"
                                v-model="settingsPage.description_2"
                                placeholder="Ingrese la descripción"
                            ></el-input>

                        </el-form-item>

                    </div>

                    <div class="row mx-0 w-100 px-2">

                        <div class="col-md-5 px-0">

                            <el-form-item
                                prop="prevention"
                                id="prevention"
                                class="row mx-0"
                            >

                                <label class="pt-2 mr-3">
                                     Ingrese modelo de prevención:
                                </label>

                                <el-upload
                                    class="upload-demo"
                                    action="#"
                                    :on-change="handleChangePrevention"
                                    :on-remove="handleRemovePrevention"
                                    :on-preview="handlePreviewPrevention"
                                    :auto-upload="false"
                                    :limit="1"
                                    :on-exceed="handleExceedPrevention"
                                    :file-list="settingsPage.prevention"
                                    accept="application/pdf"
                                >

                                    <el-button size="small" type="primary">
                                         Click para subir archivo
                                    </el-button>

                                    <div slot="tip" class="el-upload__tip">
                                         Tamaño máximo de archivo 10MB en formato
                                        PDF
                                    </div>

                                </el-upload>

                            </el-form-item>

                        </div>

                        <div class="col-md-6 px-0">

                            <el-form-item
                                prop="interaction"
                                id="interaction"
                                class="row mx-0"
                            >

                                <label class="pt-2 mr-3">
                                     Ingrese protocolo de interacción:
                                </label>

                                <el-upload
                                    class="upload-demo"
                                    action="#"
                                    :on-change="handleChangeInteraction"
                                    :on-remove="handleRemoveInteraction"
                                    :on-preview="handlePreviewInteraction"
                                    :auto-upload="false"
                                    :limit="1"
                                    :on-exceed="handleExceedInteraction"
                                    :file-list="settingsPage.interaction"
                                    accept="application/pdf"
                                >

                                    <el-button size="small" type="primary">
                                         Click para subir archivo
                                    </el-button>

                                    <div slot="tip" class="el-upload__tip">
                                         Tamaño máximo de archivo 10MB en formato
                                        PDF
                                    </div>

                                </el-upload>

                            </el-form-item>

                        </div>

                    </div>

                    <div class="row mx-0 w-100">

                        <label
                            class="pt-2"
                            style="font-size: 1rem"
                            id="description_3"
                        >
                             Página 3
                        </label>

                        <div class="row mx-0 w-100">

                            <el-form-item
                                prop="title_3"
                                id="title_3"
                                class="col-md-3 px-1"
                                style="align-items: flex-start !important"
                            >

                                <label class="pt-2">Título: </label>

                                <el-input
                                    type="text"
                                    v-model="settingsPage.title_3"
                                />

                            </el-form-item>

                            <el-form-item
                                prop="description_3"
                                class="col-md-9 px-1"
                            >

                                <label class="pt-2">Descripción: </label>

                                <el-input
                                    type="textarea"
                                    :rows="6"
                                    v-model="settingsPage.description_3"
                                    placeholder="Ingrese la descripción"
                                ></el-input>

                            </el-form-item>

                        </div>

                    </div>

                    <div class="row mx-0 w-100">

                        <label
                            class="pt-2"
                            style="font-size: 1rem"
                            id="description_4"
                        >
                             Página 4
                        </label>

                        <div class="row mx-0 w-100">

                            <el-form-item
                                prop="title_4"
                                id="title_4"
                                class="col-md-3 px-1"
                                style="align-items: flex-start !important"
                            >

                                <label class="pt-2">Título: </label>

                                <el-input
                                    type="text"
                                    v-model="settingsPage.title_4"
                                />

                            </el-form-item>

                            <el-form-item
                                prop="description_4"
                                class="col-md-9 px-1"
                            >

                                <label class="pt-2">Descripción: </label>

                                <el-input
                                    type="textarea"
                                    :rows="6"
                                    v-model="settingsPage.description_4"
                                    placeholder="Ingrese la descripción"
                                ></el-input>

                            </el-form-item>

                        </div>

                    </div>

                    <div class="row mx-0 w-100">

                        <label
                            class="pt-2"
                            style="font-size: 1rem"
                            id="description_5"
                        >
                             Página 5
                        </label>

                    </div>

                    <div class="row mx-0 w-100">

                        <el-form-item
                            prop="title_5"
                            id="title_5"
                            class="col-md-3 px-1"
                            style="align-items: flex-start !important"
                        >

                            <label class="pt-2">Título: </label>

                            <el-input
                                type="text"
                                v-model="settingsPage.title_5"
                            />

                        </el-form-item>

                        <el-form-item
                            prop="description_5"
                            class="col-md-9 px-1"
                        >

                            <label class="pt-2">Descripción: </label>

                            <el-input
                                type="textarea"
                                :rows="6"
                                v-model="settingsPage.description_5"
                                placeholder="Ingrese la descripción"
                            ></el-input>

                        </el-form-item>

                    </div>

                </el-form>

            </div>

            <div
                class="d-flex justify-content-center p-2 mb-3 w-100"
                v-if="setting_id"
            >

                <button
                    @click.prevent="submitForm('ruleForm')"
                    :disabled="waiting"
                    class="btn btn-info mr-4 text-white mx-auto"
                >
                     Guardar
                </button>

            </div>

        </div>

    </div>

</template>

<script>
export default {
    props: ["settings", "settingsPages"],
    data() {
        let checkDescription = (rule, value, callback) => {
            if (!this.truthty(value)) {
                let name = "";
                if (rule.field == "description_1") name = "la linea ética";
                else if (rule.field == "description_2")
                    name = "las buenas practicas";
                else if (rule.field == "description_3") name = "la pagina 3";
                else if (rule.field == "description_4") name = "la pagina 3";
                else if (rule.field == "description_5") name = "la pagina 3";
                return callback(
                    new Error(`Debe agregar la descripción de ${name}`)
                );
            }
            return callback();
        };
        let checkTitle = (rule, value, callback) => {
            if (!this.truthty(value)) {
                let name = "";
                if (rule.field == "title_3") name = "3";
                else if (rule.field == "title_4") name = "4";
                else if (rule.field == "title_5") name = "5";
                return callback(
                    new Error(`Debe agregar el título de la pagina ${name}`)
                );
            }
            return callback();
        };
        let checkFile = (rule, value, callback) => {
            if (!this.truthty(value)) {
                let name = "";
                if (rule.field == "privacy") name = "política de privacidad";
                else if (rule.field == "prevention")
                    name = "modelo de prevención";
                else if (rule.field == "interaction")
                    name = "protocolo de interacción";
                return callback(
                    new Error(`Debe agregar el archivo de ${name}`)
                );
            }
            return callback();
        };
        return {
            settingsPage: {
                description_1: "",
                description_2: "",
                description_3: "",
                description_4: "",
                description_5: "",
                title_3: "",
                title_4: "",
                title_5: "",
                privacy: [],
                prevention: [],
                interaction: []
            },
            setting_id: undefined,
            settingsPagesFive: [],
            waiting: false,
            rules: {
                // description_1: [
                //   { validator: checkDescription, trigger: ["change", "blur"] },
                // ],
                // description_2: [
                //   { validator: checkDescription, trigger: ["change", "blur"] },
                // ],
                // description_3: [
                //   { validator: checkDescription, trigger: ["change", "blur"] },
                // ],
                // description_4: [
                //   { validator: checkDescription, trigger: ["change", "blur"] },
                // ],
                // description_5: [
                //   { validator: checkDescription, trigger: ["change", "blur"] },
                // ],
                title_3: [
                    { validator: checkTitle, trigger: ["change", "blur"] }
                ],
                title_4: [
                    { validator: checkTitle, trigger: ["change", "blur"] }
                ],
                title_5: [
                    { validator: checkTitle, trigger: ["change", "blur"] }
                ]
            }
        };
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid, rules) => {
                if (valid) {
                    this.submit();
                } else {
                    this.goToError(rules);
                }
            });
        },
        clearForm() {
            this.settingsPage = {
                description_1: "",
                description_2: "",
                description_3: "",
                description_4: "",
                description_5: "",
                title_3: "",
                title_4: "",
                title_5: "",
                privacy: [],
                prevention: [],
                interaction: []
            };
            this.$refs["ruleForm"].resetFields();
        },
        getSettingsPages($event) {
            const url = `${window.location.origin}/settingsPage/getAll/${this.setting_id}`;
            axios
                .get(url, {})
                .then(res => {
                    let privacy = [];
                    let prevention = [];
                    let interaction = [];
                    this.settingsPagesFive = res.data.settingsPagesFive;
                    this.links = res.data.temporaryLinks;
                    this.titles = res.data.titles;
                    if (
                        this.truthty(this.titles[0]) &&
                        this.truthty(this.links[0])
                    ) {
                        privacy.push({
                            name: this.titles[0],
                            url: this.links[0]
                        });
                    }
                    if (
                        this.truthty(this.titles[1]) &&
                        this.truthty(this.links[1])
                    ) {
                        prevention.push({
                            name: this.titles[1],
                            url: this.links[1]
                        });
                    }
                    if (
                        this.truthty(this.titles[2]) &&
                        this.truthty(this.links[2])
                    ) {
                        interaction.push({
                            name: this.titles[2],
                            url: this.links[2]
                        });
                    }
                    this.settingsPage = {
                        description_1: this.settingsPagesFive[0].description,
                        description_2: this.settingsPagesFive[1].description,
                        description_3: this.settingsPagesFive[2].description,
                        description_4: this.settingsPagesFive[3].description,
                        description_5: this.settingsPagesFive[4].description,
                        title_3: this.settingsPagesFive[2].title,
                        title_4: this.settingsPagesFive[3].title,
                        title_5: this.settingsPagesFive[4].title,
                        privacy,
                        prevention,
                        interaction
                    };
                })
                .catch(error => {
                    console.error(error);
                });
        },
        handlePreviewPrivacy(file) {
            if (this.truthty(file.url)) {
                window.open(file.url, "_blank");
            }
        },
        handlePreviewPrevention(file) {
            if (this.truthty(file.url)) {
                window.open(file.url, "_blank");
            }
        },
        handlePreviewInteraction(file) {
            if (this.truthty(file.url)) {
                window.open(file.url, "_blank");
            }
        },
        handleChangePrivacy($e) {
            this.settingsPage.privacy.push($e.raw);
            this.$refs["ruleForm"].validateField("privacy");
        },
        handleChangePrevention($e) {
            this.settingsPage.prevention.push($e.raw);
            this.$refs["ruleForm"].validateField("prevention");
        },
        handleChangeInteraction($e) {
            this.settingsPage.interaction.push($e.raw);
            this.$refs["ruleForm"].validateField("interaction");
        },
        handleRemovePrivacy(file, privacy) {
            this.settingsPage.privacy = privacy;
            this.$refs["ruleForm"].validateField("privacy");
        },
        handleRemovePrevention(file, prevention) {
            this.settingsPage.prevention = prevention;
            this.$refs["ruleForm"].validateField("prevention");
        },
        handleRemoveInteraction(file, interaction) {
            this.settingsPage.interaction = interaction;
            this.$refs["ruleForm"].validateField("interaction");
        },
        handleExceedPrivacy(files, privacy) {
            this.$message.warning(
                `Solo puedes subir 1 archivo de política de privacidad.`
            );
        },
        handleExceedPrevention(files, prevention) {
            this.$message.warning(
                `Solo puedes subir 1 archivo de modelo de prevención.`
            );
        },
        handleExceedInteraction(files, interaction) {
            this.$message.warning(
                `Solo puedes subir 1 archivo de protocolo de interacción.`
            );
        },
        submit() {
            this.waiting = true;
            const url = `${window.location.origin}/settingsPage/update/${this.setting_id}`;
            let config = { headers: { "Content-Type": "multipart/form-data" } };
            let formData = new FormData();
            formData.append("payload", JSON.stringify(this.settingsPage));
            this.settingsPage.privacy.map(x => {
                formData.append("file1", x);
            });
            this.settingsPage.prevention.map(x => {
                formData.append("file2", x);
            });
            this.settingsPage.interaction.map(x => {
                formData.append("file3", x);
            });
            axios
                .post(url, formData, config)
                .then(res => {
                    this.waiting = false;
                    let privacy = [];
                    let prevention = [];
                    let interaction = [];
                    this.links = res.data.temporaryLinks;
                    this.titles = res.data.titles;
                    this.settingsPagesFive = res.data.newPages;
                    if (
                        this.truthty(this.titles[0]) &&
                        this.truthty(this.links[0])
                    ) {
                        privacy.push({
                            name: this.titles[0],
                            url: this.links[0]
                        });
                    }
                    if (
                        this.truthty(this.titles[1]) &&
                        this.truthty(this.links[1])
                    ) {
                        prevention.push({
                            name: this.titles[1],
                            url: this.links[1]
                        });
                    }
                    if (
                        this.truthty(this.titles[2]) &&
                        this.truthty(this.links[2])
                    ) {
                        interaction.push({
                            name: this.titles[2],
                            url: this.links[2]
                        });
                    }
                    this.settingsPage = {
                        description_1: this.settingsPagesFive[0].description,
                        description_2: this.settingsPagesFive[1].description,
                        description_3: this.settingsPagesFive[2].description,
                        description_4: this.settingsPagesFive[3].description,
                        description_5: this.settingsPagesFive[4].description,
                        title_3: this.settingsPagesFive[2].title,
                        title_4: this.settingsPagesFive[3].title,
                        title_5: this.settingsPagesFive[4].title,
                        privacy,
                        prevention,
                        interaction
                    };
                    this.$message({
                        message: res.data.message,
                        type: "success"
                    });
                    let element = document.getElementById("ruleForm");
                    if (this.truthty(element))
                        element.scrollIntoView({ behavior: "smooth" });
                })
                .catch(error => {
                    this.waiting = false;
                    this.$message({
                        message: error.response.data.message,
                        type: "error",
                        duration: 2000
                    });
                });
        }
    }
};
</script>

